import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useMemo } from 'react'

// Components
import { SearchField } from 'components/tools/search_field'; 
import { useTranslation } from 'react-i18next';

// API
import { searchUsers } from 'api/users';
import { useSearch } from 'hooks/useSearch';

export function SearchUser({value, setValue, disabled, color, size, label, defaultParams, targetWidth}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState();
  const p = useMemo(()=>({...defaultParams, ...params}), [params]);
  const [users, {loading}] = useSearch(searchUsers, p)
  return <SearchField values={users}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      disabled={disabled}
                      color={color}
                      size={size}
                      label={label}
                      targetWidth={targetWidth}
                      placeholder={t("users")}
                      indexingField={"email"}
                      formatSelectedValue={(d=>d&&d.name)}
                      formatSearchResult={((user, {selected, active})=>
                        <div>
                          <span className={classnames('block truncate', (active || selected) && 'font-semibold')}>{user.name}</span>
                        </div>)}
                     onParamsChange={setParams}
  />
}
