import React, { useEffect, useMemo} from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiToggleRight } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

export function SelectStatsFiltering({ value, setValue, Icon, disabled, preset, ...props }) {
    const { t } = useTranslation("common");

    const values = [
        preset ? 
        {
            name: t("students-from-preset.title"),
            longDescription: t("students-from-preset.description"),
            value: "preset"
        } : {
            name: t("assignations"),
            longDescription: t("assignations-description"),
            value: "assignations"
        },
        {
            name: t("all-students.title"),
            longDescription: t("all-students.description"),
            value: "all"
        }
    ];

    const controlledValue = useMemo(() => {
        if (!value || !values.some(d => d.value === (value.value || value))) {
            return values[0];
        }
        return value.value ? value : values.find(d => d.value === value);
    }, [value, values]);

    useEffect(() => {
        setValue(controlledValue);
    }, [controlledValue]);

    return (
        <ListSelection
            value={controlledValue}
            setValue={setValue}
            disabled={disabled}
            values={values}
            label={t('yes/no')}
            Icon={Icon || FiToggleRight}
            getKey={(d) => d.value}
            format={(d) => d.name}
            withClearSelection={false}
            {...props}
        />
    );
}