import React, { useReducer, useMemo, createContext, useEffect } from "react";
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useParams, Navigate, useLocation } from 'react-router-dom';

import { usePreset } from 'hooks/presets/usePreset';

function filterReducer(state, action){
  switch (action.type){
    case 'team':
      return {...state, team: action.value};
    case 'preset':
      return {...state, preset: action.value, team: null};
    case 'user':
        return {...state, user: action.value};
    case 'weekdays':
      return {...state, weekdays: action.value};
    default:
      return state
  }
}

const TutoringFollowUpContext = createContext({});

const TutoringFollowUpContextProvider = ({ children, doNotStore, storageKey, defaultState }) => {
  const location = useLocation();
  const params = useParams();
  const [preset] = usePreset({immediate: true})
  const [defaultFilters, setDefaultFilters] = useLocalStorage(storageKey || (location.pathname+'_filters'));

  const parsedDefaultFilters = useMemo(()=>{
    if (!defaultFilters || doNotStore) return;
    if (defaultFilters.fromDate) defaultFilters.fromDate = new Date(defaultFilters.fromDate);
    if (defaultFilters.toDate) defaultFilters.toDate = new Date(defaultFilters.toDate);
    if (defaultFilters.date) defaultFilters.date = new Date(defaultFilters.date);
    return defaultFilters;
  }, [])

  const [filters, dispatchFilters] = useReducer(filterReducer, parsedDefaultFilters || defaultState || {});
  
  useEffect(()=>{
    if (!doNotStore) setDefaultFilters(filters);
  }, [filters]);

  if (params.presetId==null && filters.preset && filters.preset.id){
    return <Navigate to={`/tutoring-follow-up/${filters.preset.id}/bdt`}/>
  }
  return (
    <TutoringFollowUpContext.Provider value={{ filters: {preset, ...filters}, dispatchFilters }}>
      {children}
    </TutoringFollowUpContext.Provider>
  );
};

export { TutoringFollowUpContext, TutoringFollowUpContextProvider };