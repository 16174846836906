import { format } from 'd3-format';

export function cronToString(d) {
    if (!d) return "Aucune période";
    const fc = d.split(" ");
    const minutes = parseInt(fc[0]);
    const hour = parseInt(fc[1]);
    const dayInt = parseInt(fc[4]);
    const day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][dayInt]
    return `${day} à ${format("02")(hour)}:${format("02")(minutes)}`
}

export function cronDecompose(d) {
    if (!d) return "Aucune période";
    const fc = d.split(" ");
    const minutes = parseInt(fc[0]);
    const hour = parseInt(fc[1]);
    const dayInt = parseInt(fc[4]);
    const day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][dayInt]
    return { minutes, hour, day, dayInt }
}

export function hasDispo(from_cron, to_cron, days, h0, h1, minutesRequired) {
    /*Check if the cron has a availability in the interval h0, h1 for
    the days in the list days.
      Args:
      from_cron: string , cron string
      to_cron: string, cron string
      days: list of days of the weeks ('Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche')
      h0: start interval in format string (HH:MM)
      h1: end interval in format string (HH:MM)
      minutesRequired: int, number of minutes required in the interval
    */
    const { minutes: fromMinutes, hour: fromHours, day: fromDay } = cronDecompose(from_cron);
    const { minutes: toMinutes, hour: toHours, day: toDay } = cronDecompose(to_cron);
    const fromTime = fromHours * 60 + fromMinutes;
    const toTime = toHours * 60 + toMinutes;
    if (fromDay !== toDay) {
        // Must be same day
        return false;
    }
    if (days.indexOf(fromDay) === -1) {
        return false;
    }
    const h0Split = h0.split(":").map(d => parseInt(d));
    const h1Split = h1.split(":").map(d => parseInt(d));
    const h0Time = h0Split[0] * 60 + h0Split[1];
    const h1Time = h1Split[0] * 60 + h1Split[1];

    const t0 = Math.max(fromTime, h0Time);
    const t1 = Math.min(toTime, h1Time);
    return t1 - t0 >= minutesRequired;
}

export function cronsCompact(d) {
    // d is a list of objects from from_cron and to_cron (both on the same day)
    // We want to display
    if (!d || d.length === 0) return "Aucune période";
    const texts = d.map(s => cronsIntervalToString([s.from_cron, s.to_cron]).slice(0, -1));
    // Order by day of the week
    const week = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const ordered = texts.sort((a, b) => {
        const dayA = a.split(" ")[0];
        const dayB = b.split(" ")[0];
        return week.indexOf(dayA) - week.indexOf(dayB);
    });
    return ordered.join(", ");
}


export function cronsIntervalToString(d) {
    if (!d || d.length === 0) return "Aucune période";
    if (!Array.isArray(d)) return "Format invalide";
    const fc = d[0].split(" ");
    const minutes = parseInt(fc[0]);
    const hour = parseInt(fc[1]);
    const dayInt = parseInt(fc[4]);
    const day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][dayInt];

    const tc = d[1].split(" ");
    const tminutes = parseInt(tc[0]);
    const thour = parseInt(tc[1]);
    const tdayInt = parseInt(tc[4]);
    const tday = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][tdayInt];
    if (tday === day) {
        return `${day} de ${format("02")(hour)}:${format("02")(minutes)} à ${format("02")(thour)}:${format("02")(tminutes)} `
    }
    return `${day} de ${format("02")(hour)}:${format("02")(minutes)} au ${tday} ${format("02")(thour)}:${format("02")(tminutes)} `
}

/**
 * Finds the intersections between multiple sets of cron intervals.
 * @param  {...Array} cronArrays - N number of cron arrays.
 * @returns {Array} - Array of intersection objects or a message if none found.
 */
export function cronsIntersections(...cronArrays) {
    if (cronArrays.length === 0) {
        throw new Error("At least one cron array must be provided.");
    }

    // Function to intersect two cron arrays
    const intersectTwoCrons = (cronsA, cronsB) => {
        const intersections = [];

        cronsA.forEach(cron1 => {
            const { from_cron: from1, to_cron: to1 } = cron1;
            const decomposedFrom1 = cronDecompose(from1);
            const decomposedTo1 = cronDecompose(to1);

            if (!decomposedFrom1 || !decomposedTo1) return;

            cronsB.forEach(cron2 => {
                const { from_cron: from2, to_cron: to2 } = cron2;
                const decomposedFrom2 = cronDecompose(from2);
                const decomposedTo2 = cronDecompose(to2);

                if (!decomposedFrom2 || !decomposedTo2) return;

                // Check if both intervals are on the same day
                if (decomposedFrom1.dayInt !== decomposedFrom2.dayInt) return;

                // Convert times to minutes since midnight for comparison
                const start1 = decomposedFrom1.hour * 60 + decomposedFrom1.minutes;
                const end1 = decomposedTo1.hour * 60 + decomposedTo1.minutes;
                const start2 = decomposedFrom2.hour * 60 + decomposedFrom2.minutes;
                const end2 = decomposedTo2.hour * 60 + decomposedTo2.minutes;

                // Find the overlap between the two intervals
                const overlapStart = Math.max(start1, start2);
                const overlapEnd = Math.min(end1, end2);

                if (overlapEnd > overlapStart) {
                    // Convert overlapStart and overlapEnd back to hour and minute
                    const overlapStartHour = Math.floor(overlapStart / 60);
                    const overlapStartMinute = overlapStart % 60;
                    const overlapEndHour = Math.floor(overlapEnd / 60);
                    const overlapEndMinute = overlapEnd % 60;

                    // Construct new cron strings for the overlapping interval
                    const dayInt = decomposedFrom1.dayInt;
                    const newFromCron = `${format("02")(overlapStartMinute)} ${format("02")(overlapStartHour)} * * ${dayInt}`;
                    const newToCron = `${format("02")(overlapEndMinute)} ${format("02")(overlapEndHour)} * * ${dayInt}`;

                    // Generate human-readable strings
                    const fromString = cronToString(newFromCron);
                    const toString = cronToString(newToCron);

                    intersections.push({
                        from_cron: newFromCron,
                        to_cron: newToCron,
                        from: fromString,
                        to: toString
                    });
                }
            });
        });

        return intersections;
    };

    // Initialize the intersection result with the first cron array
    let currentIntersections = cronArrays[0].map(cron => ({
        from_cron: cron.from_cron,
        to_cron: cron.to_cron,
        from: cronToString(cron.from_cron),
        to: cronToString(cron.to_cron)
    }));

    // Iteratively intersect with each subsequent cron array
    for (let i = 1; i < cronArrays.length; i++) {
        currentIntersections = intersectTwoCrons(currentIntersections, cronArrays[i]);
        // If at any point the intersection is empty, no need to proceed further
        if (currentIntersections.length === 0) break;
    }

    return currentIntersections;
}