/**
 * Finds the common subjects across multiple subject arrays.
 * 
 * Each subject is an object with at least a 'slug' and 'name' property.
 * The intersection is based on the 'slug' property.
 * 
 * @param  {...Array} subjectArrays - N number of arrays containing subject objects.
 * @returns {Array} - Array of subject objects common to all input arrays.
 */
export function subjectsIntersections(...subjectArrays) {
    if (subjectArrays.length === 0) return [];

    // Initialize the intersection set with slugs from the first array
    let intersectionSlugs = new Set(subjectArrays[0].map(subject => subject.slug));

    // Iterate over the remaining arrays to compute the intersection
    for (let i = 1; i < subjectArrays.length; i++) {
        const currentSlugs = new Set(subjectArrays[i].map(subject => subject.slug));
        intersectionSlugs = new Set([...intersectionSlugs].filter(slug => currentSlugs.has(slug)));
        
        // If at any point the intersection is empty, no need to proceed further
        if (intersectionSlugs.size === 0) break;
    }

    // Retrieve the subject objects corresponding to the intersected slugs
    const commonSubjects = subjectArrays[0].filter(subject => intersectionSlugs.has(subject.slug));

    return commonSubjects;
}
